<script>
	import { createEventDispatcher, tick } from "svelte";
	import { fade } from "svelte/transition";
	import MuteButton from "./MuteButton.svelte";

	const dispatch = createEventDispatcher();
	const loaded = new Map();

	export let src;
	export let alt = "";
	export let width = 0;
	export let height = 0;
	export let isRounded = false;
	export let hasMute = false;
	
	let isMuted = true;
	let clientWidth = 0;
	let hasLoaded = false;
	let minHeight;

	if(width > 0 && height === 0){
		height = clientWidth * 1.91;
		minHeight = height;
	}

	let isVideo = src.indexOf(".mp4") !== -1;
	if (isVideo) {
	  videoLoaded();
	}

	async function videoLoaded() {
	  await tick();
	  dispatch("loaded");
	}

	function lazy(node, data) {
	  if (!loaded.has(data.src)) {
	    const img = new Image();
	    img.src = data.src;
	    img.onload = () => {
	      loaded.set(data.src, img);
	      node.setAttribute("src", data.src);
	      width = img.width;
	      height = img.height;
	      hasLoaded = true;
	      dispatch("loaded");
	    };
	  }
	  node.setAttribute("src", data.src);

	  return {
	    destroy() {} // noop
	  };
	}
</script>
	<div
			bind:clientWidth
			class={`w-full h-auto ${isRounded ? "rounded-2xl" : ""}}`}
		>
		{#if !isVideo}
			<img
				class="image w-full m-auto p-0 block overflow-hidden"
				width={`${width}px`}
				height={`${height}px`}
				alt={alt || ' '}
		    style={`
				display: none;
				${(minHeight) ? minHeight + 'px;' : ''}
		    `}
		    use:lazy="{{src: `${src}`}}"
			>

			{#if hasLoaded}
				<img
				 	transition:fade
					alt={alt || ''}
					role={(alt) ? '' : 'none'}
					class="image w-full h-auto m-auto p-0 block overflow-hidden"
					width={`${width}px`}
					height={`${height}px`}
			    use:lazy="{{src: `${src}`}}"
				>
			{/if}
		{:else}
			<div class="relative">
				<video width={331} height={168}
						class={`image w-full h-auto  p-0 block overflow-hidden ${isRounded ? "rounded-2xl" : ""}`}
						preload autoplay muted={isMuted} loop playsinline poster="{src.replace('.mp4', '.jpg')}">
					<source src={src} type="video/mp4">
					Your browser does not support the video tag.
				</video>
				{#if hasMute}
					<!-- svelte-ignore a11y-click-events-have-key-events -->
					<div class="absolute bottom-0 right-0 p-1 mb-1" on:click={() => isMuted = !isMuted}>
						<MuteButton enabled={true} muted={isMuted} />
					</div>
				{/if}
			</div>
		{/if}
	</div>
