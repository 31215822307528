<script>
    export let muted = true;
    let reverse = false
</script>
<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<div class="pr-1 cursor-pointer"
    on:mouseover={() => reverse = true}
     on:mouseleave={() => reverse = false}>
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none">
        <circle cx="13" cy="13" r="13" fill={(reverse) ? 'white' : 'black'}/>
        <path d="M19.27 10.96C18.99 10.29 18.58 9.68 18.08 9.18C17.84 8.94 17.46 8.94 17.23 9.18C16.99 9.42 16.99 9.8 17.23 10.03C17.63 10.43 17.94 10.9 18.16 11.42C18.37 11.92 18.48 12.47 18.48 13.06C18.48 13.65 18.37 14.2 18.16 14.7C17.95 15.22 17.63 15.69 17.23 16.09C16.99 16.33 16.99 16.71 17.23 16.94C17.47 17.17 17.85 17.18 18.08 16.94C18.58 16.44 18.99 15.83 19.27 15.16C19.54 14.51 19.69 13.81 19.69 13.07C19.69 12.33 19.54 11.62 19.27 10.98V10.96Z" fill={(!reverse) ? 'white' : 'black'}/>
        <path d="M17.44 13.05C17.44 12.62 17.35 12.2 17.19 11.82C17.02 11.42 16.78 11.06 16.49 10.77C16.25 10.53 15.87 10.53 15.64 10.77C15.4 11.01 15.4 11.39 15.64 11.62C15.83 11.81 15.98 12.03 16.08 12.28C16.18 12.52 16.23 12.78 16.23 13.06C16.23 13.34 16.18 13.6 16.08 13.84C15.98 14.08 15.83 14.31 15.64 14.5C15.4 14.74 15.4 15.12 15.64 15.35C15.88 15.59 16.26 15.59 16.49 15.35C16.79 15.05 17.03 14.7 17.19 14.3C17.35 13.92 17.44 13.5 17.44 13.07V13.05Z" fill={(!reverse) ? 'white' : 'black'}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 7L10 10H8C7.44772 10 7 10.4477 7 11V15C7 15.5523 7.44772 16 8 16H10L14.5 19V7Z" fill={(!reverse) ? 'white' : 'black'}/>
        <mask id="path-5-outside-1_624_192" maskUnits="userSpaceOnUse" x="7.29282" y="7" width="12.7279" height="12.7279" fill={(reverse) ? 'white' : 'black'}>
        <rect fill={(!reverse) ? 'white' : 'black'} x="7.29282" y="7" width="12.7279" height="12.7279"/>
        <path d="M8.35348 7.35355C8.54874 7.15829 8.86532 7.15829 9.06058 7.35355L19.6672 17.9602C19.8624 18.1554 19.8624 18.472 19.6672 18.6673V18.6673C19.4719 18.8625 19.1553 18.8625 18.9601 18.6673L8.35348 8.06066C8.15822 7.8654 8.15822 7.54882 8.35348 7.35355V7.35355Z"/>
        </mask>
        {#if muted}
            <path d="M8.35348 7.35355C8.54874 7.15829 8.86532 7.15829 9.06058 7.35355L19.6672 17.9602C19.8624 18.1554 19.8624 18.472 19.6672 18.6673V18.6673C19.4719 18.8625 19.1553 18.8625 18.9601 18.6673L8.35348 8.06066C8.15822 7.8654 8.15822 7.54882 8.35348 7.35355V7.35355Z" fill={(!reverse) ? 'white' : 'black'}/>
            <path d="M8.70703 7L20.0207 18.3137L8.70703 7ZM19.6672 18.6673C19.0814 19.253 18.1317 19.253 17.5459 18.6673L8.35348 9.47487C7.76769 8.88909 7.76769 7.93934 8.35348 7.35355C8.44725 7.25979 8.57442 7.20711 8.70703 7.20711C8.83964 7.20711 8.96682 7.25979 9.06058 7.35355L9.06058 7.35355L8.70703 7L19.6672 17.9602C19.761 18.0539 19.8136 18.1811 19.8136 18.3137C19.8136 18.4463 19.761 18.5735 19.6672 18.6673L19.6672 18.6673ZM7.99992 7.70711L8.70703 7L7.99992 7.70711ZM20.0207 18.3137L19.3136 19.0208L20.0207 18.3137Z" fill={(!reverse) ? 'white' : 'black'} mask="url(#path-5-outside-1_624_192)"/>
        {/if}
    </svg>
  </div>