<script>
	import { scale } from 'svelte/transition';
  import TypingIndicator from "./TypingIndicator.svelte";
  import snarkdown from "snarkdown";
  import "../../styles/tailwind.css";
	import { bounceOut } from 'svelte/easing';

  export let text;
  export let styles;
  export let hasBottomMargin = false;
  export let rtl = false;
  export let width;

  const animate = (node, args) =>
		args.cond ? fade(node, args) : scale(node, args);


</script>
<div class="button-text origin-top-left text-sm whitespace-prewrap user-bubble ${(hasBottomMargin) ? 'user-bubble-last' : ''} mb-1 px-3 py-2 inline-block rounded-2xl leading-custom content-border mx-3 snarkdown" 
  in:animate={{ duration: 500, delay: 125, start: 1.1, easing: bounceOut }}
  style={`
    background-color: ${styles.cardBackground};
    ${(styles.cardBorder) ? "border: " + styles.cardBorder + ";" : ""}
    color: ${(styles.cardTextColor ? styles.cardTextColor : styles.secondaryBrandColor)};
    ${(rtl) ? 'direction: rtl;' : ''}
    width: ${(width) ? width : 'auto'};
    max-width: ${(parseInt(width) > 80) ? width : '83%'};
  `}>
  {@html snarkdown(text)}
</div>

<style>
  .button-text{
    transform: translate3d(0px, 0px, 0px);
  }
</style>
