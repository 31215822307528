
<script>
  export let pixelName = '';
  export let pixels = {};

  let pixelList = []

  $: if (pixelName) {
    console.log(pixelName)
    pixelList.push(pixels[pixelName])
  }
</script>
{#each pixelList as pixel}
  <img
    class='pixel-tracking'
    src={pixels[pixelName]}
    border="0"
    height="1"
    width="1"
    alt=""
  />
{/each}