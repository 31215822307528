class URLToExitMap{
  constructor(exits){
    this.exits = exits
  }
  getMapping(url){
    const exit = this.exits[url]
    return exit
  }
}

export default URLToExitMap;
