<script>
	import emblaCarouselSvelte from 'embla-carousel-svelte';
	import { onMount, createEventDispatcher } from 'svelte';
  
	export let loop = true;
	export let autoplay = 0;
	export let duration = 200;
	export let startIndex = 0;
	export let draggable = true;
	export let controls = true;
	export let threshold = 20;
	export let arrowButtonY = '70px';
	export let currentIndex = 0;
  
	let emblaApi;
	let emblaNode;
	let timer;
	let clientWidth;
	let buttonClasses = "absolute w-[40px] h-[40px] top-[50%] -mt-[-20px] border-none bg-transparent opacity-100";
	let leftEnabled = true;
	let rightEnabled = true;
  
	const dispatch = createEventDispatcher();
  
	// Options for Embla Carousel
	const options = {
	  loop,
	  startIndex,
	  draggable,
	  speed: duration,
	  dragThreshold: threshold,
	};
  
	// Event handler for when Embla initializes
	function onInit(event) {
	  emblaApi = event.detail;
	  currentIndex = emblaApi.selectedScrollSnap();
	  updateButtonState();
	  emblaApi.on('select', handleChange);
  
	  if (autoplay) {
		timer = setInterval(right, autoplay);
	  }
	  dispatch('change', {
		currentSlide: currentIndex,
		slideCount: emblaApi.slideNodes().length,
	  });
	}
  
	function handleChange() {
	  currentIndex = emblaApi.selectedScrollSnap();
	  updateButtonState();
	  dispatch('change', {
		currentSlide: currentIndex,
		slideCount: emblaApi.slideNodes().length,
	  });
	}
  
	function updateButtonState() {
	//   leftEnabled = !emblaApi.canScrollPrev();
	//   rightEnabled = !emblaApi.canScrollNext();
	}
  
	export function left() {
	  emblaApi.scrollPrev();
	}
  
	export function right() {
	  emblaApi.scrollNext();
	}
  
	export function go(index) {
	  if (emblaApi) emblaApi.scrollTo(index);
	}
  
	export function pause() {
	  clearInterval(timer);
	}
  
	export function resume() {
	  if (autoplay) {
		timer = setInterval(right, autoplay);
	  }
	}
  
	function getRightArrowPosition(width) {
	  if (width <= 300) {
		return "105%";
	  } else if (width <= 500) {
		return "97%";
	  } else if (width <= 600) {
		return "100%";
	  }
	  return "102%";
	}
  </script>
  
  <div class="carousel relative w-[100%]" bind:clientWidth={clientWidth}>
	<div class="embla" bind:this={emblaNode} use:emblaCarouselSvelte={options} on:emblaInit={onInit}>
	  <div class="embla__container">
		<slot></slot>
	  </div>
	</div>
	{#if controls}
	  {#if leftEnabled}
		<button class={`left ${buttonClasses} left-0`}
				style={`top: ${arrowButtonY};`}
				on:click={left}
				aria-label="left">
		  <slot name="left-control"></slot>
		</button>
	  {/if}
	  {#if rightEnabled}
		<button class={`right hover:opacity-70 ${buttonClasses}`}
				style={`left: ${getRightArrowPosition(clientWidth)}; top: ${arrowButtonY};`}
				on:click={right}
				aria-label="right">
		  <slot name="right-control"></slot>
		</button>
	  {/if}
	{/if}
  </div>
  
  <style>
	/* Optional: Add your own styles for the embla carousel */
	.embla {
	  overflow: visible;
	  width: 100%;
	}
	.embla__container {
	  display: flex;
	}
	.embla__slide {
	  position: relative;
	  min-width: 100%;
	}
  </style>
  