``// this is an example of the perPage format required by
// the Siema carousel library: { 800: 3, 500: 2, 300: 1 }
export default function displayedCardCount(clientWidth){
	const perPage = { 800: 3, 500: 2, 300: 1 }
	if(!clientWidth) return 1
	// turn the perPage object into an array
	let perPageArray = [{breakpoint: 0, numberOfCards: 1}]
	for(let i in perPage){
		perPageArray.push({breakpoint: parseInt(i), numberOfCards: perPage[i]});
	}
	// grok the array and find the number of cards
	for(let j = 0; j < perPageArray.length; j++){
		let item = perPageArray[j];
		if(!perPageArray[j + 1]) return perPageArray[j].numberOfCards
		let nextItem = perPageArray[j + 1];
		if(clientWidth >= item.breakpoint && clientWidth < nextItem.breakpoint){
			return item.numberOfCards;
		}
	}
	return 1
}
