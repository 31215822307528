<script>
  import ScrollContainer from "./ScrollContainer.svelte";
  import InputContainer from "./InputContainer.svelte";
  import ChatInterface from "../../lib/ChatInterface.js";
  import StaggeredContent from "../../lib/StaggeredContent.js";

  import { createEventDispatcher, beforeUpdate } from "svelte";

  export let style;
  export let width;
  export let height;
  export let intentNameCallback = null;
  export let config;
  export let darkMode = false;
  export let loadMode = false;
  export let startingPhrase = "";
  export let content = [];
  export let includeInput = true;
  export let submissionPhrase = null  ;
  export let startingPhraseWasTyped = false;
  export let carouselArrowButtonY = "65px";
  export let ySpaceBetweenCards = null;
  export let chatTimingMS = 600;
  export let analytics = null;
  export let customPayloadRenderer = null;
  export let customPayloadChangeHandler = null;
  export let hasMute = false;
  export let cardWidth;

  let clientHeight;
  let hasStarted;
  let isLoading;

  let chatInterface = new ChatInterface();
  let staggeredContent = new StaggeredContent([], chatTimingMS, updateCallback);
  
  $: {
    submitText(submissionPhrase);
  }

  const dispatch = createEventDispatcher();

  beforeUpdate(() => {
    if (startingPhrase.length !== 0 && !hasStarted) {
      submitText(startingPhrase, startingPhraseWasTyped);
    }
  });

  function textChangeHandler(event) {
    dispatch("textChange", event.detail);
  }

  function handlePostback(event) {
    submitText(event.detail);
    dispatch("postback", event.detail);
  }

  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  async function updateCallback(items, turnOffLoadMode, count) {
    loadMode = !turnOffLoadMode;
    content = items;
  }

  function handleTextSubmit(event) {
    submitText(event.detail, true);
  }

  function handleInstruction(event) {
    dispatch("instruction", event.detail);
  }

  async function submitText(phrase, wasTyped) {
    if (phrase && phrase.length) {
      loadMode = true;
      hasStarted = true;
      staggeredContent.add([
        {
          type: "userResponse",
          text: phrase
        }
      ]);
      isLoading = true;
      dispatch("textSubmit", { phrase: phrase, wasTyped: wasTyped });
      const response = await chatInterface.chat(
        phrase,
        config,
        intentNameCallback,
        config.rtl,
        config.customDimensions
      );
      staggeredContent.add(response);
      // await tick();
      setTimeout(() => {
        isLoading = false;
      }, 500);
    }
  }

</script>
<div bind:clientHeight class="chat-wrapper h-full w-full"
  style={`max-width: ${width};`}
>
  <slot name="intro-header">
  </slot>
  <slot name="chat-header">
  </slot>
  <div class="scroll-wrapper">
    <ScrollContainer
      darkMode={darkMode}
      styles={style}
      width={width}
      height={height}
      content={content}
      isLoading={isLoading || loadMode}
      rtl={config.rtl}
      analytics={analytics}
      carouselArrowButtonY={carouselArrowButtonY}
      ySpaceBetweenCards={ySpaceBetweenCards}
      customPayloadRenderer={customPayloadRenderer}
      chatTimingMS={chatTimingMS}
      cardWidth={cardWidth}
      hasMute={hasMute}
      on:change={customPayloadChangeHandler}
      on:postback={handlePostback}
      on:openUrl={handleOpenURL}
      on:instruction={handleInstruction}
    />
  </div>
  {#if includeInput}
    <InputContainer
      style={style}
      inputConfig={config.inputConfig}
      on:textChange={textChangeHandler}
      on:textSubmit={handleTextSubmit}
    />
  {/if}
</div>
