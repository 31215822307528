<script>
    import { createEventDispatcher } from "svelte";
    import { fade, scale } from "svelte/transition";
    import { quintOut } from "svelte/easing";
	import TypingIndicator from "./TypingIndicator.svelte";
	import BetterIconButton from "src/better/BetterIconButton.svelte";
	import IconButton from "./IconButton.svelte";
	import MuteButton from "./MuteButton.svelte";

    export let mediaHeight = null;
    export let altTag = null;
    export let src = null;
    export let delay = 750;
    export let style;
    export let makeHotSpot = false;
    export let hasEngaged = false;
    export let shouldLoop = false;
    export let hasMute = true;

    let dispatch = createEventDispatcher();
    let visible = false;
    let hasDelayed = false;
    let imageHasLoaded = false;
    let isVideo = src.indexOf('mp4') > -1;
    let imagesHaveBorders = false;
    let posterImage = src.replace('.mp4', '.jpg');
    let hasPlayed = false;
    let muted = true;

    // Watch for changes in hasEngaged using $
    $: {
        if (isVideo && hasEngaged) {
            hasPlayed = false;
            const videoEl = document.getElementById("adchat-loadingImage-videoPlayer");
            if (videoEl) {
                videoEl.play();
            }
        }
    }

    const animate = (node, args) =>{
        if(!args) return {};
		args.cond ? fade(node, args) : scale(node, args);}

    function imageLoadedHandler(){
        imageHasLoaded = true;
        if(hasDelayed)
            visible = true
            dispatch('imageLoaded', src)
    }

    function handleClick(){
        dispatch('imageClick', src)
    }

    function getVideoUrl(filename) {
        if (window.Enabler && Enabler && Enabler.isServingInLiveEnvironment()) {
            return Enabler.getUrl(filename); 
        } else {
            return filename;
        }
    }

    setTimeout(() => {
        hasDelayed = true;
        if(imageHasLoaded)
            visible = true
            dispatch('imageLoaded', src)
    }, delay)

    function videoCompleteHandler(){
        hasPlayed = true;
    }

    function handleMuteClick(){
        const videoEl = document.getElementById("adchat-loadingImage-videoPlayer");
        if (videoEl) {
            videoEl.muted = !videoEl.muted;
        }
        muted = !muted;
    }   

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="relative">
    {#if !visible}
            <div class="absolute top-0 left-0 -mx-2" >
                    <TypingIndicator styles={style}/>
            </div>  
    {/if}
    {#if !isVideo}
        {#if src && (imageHasLoaded && hasDelayed)}
            <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left ${(visible) ? 'visible' : 'invisible'}  ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
                alt={altTag}
                in:animate={{ duration: 250, start: 0.75, easing: quintOut }}
                style={`max-height: ${mediaHeight}px;`}
                src={src} 
                on:load={imageLoadedHandler}
                on:click={handleClick} 
            />
        {:else}
        {#if src && (hasDelayed)}
            <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left} ${(visible) ? 'visible' : 'invisible'}`}
                alt={altTag}
                style={`
                    max-height: ${mediaHeight}px;`}
                src={src} 
                on:load={imageLoadedHandler}
                on:click={handleClick} 
            />
        {/if}
    {/if}
    {:else}
        {#if !hasPlayed}
            <div class="relative">
                <!-- svelte-ignore a11y-media-has-caption -->
                <video 
                    id="adchat-loadingImage-videoPlayer"
                    class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left h-auto ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
                    height={mediaHeight}
                    preload autoplay muted playsinline 
                    poster={posterImage}
                    loop={shouldLoop && hasEngaged}
                    style={`max-height: ${mediaHeight}px;`}
                    on:loadeddata={imageLoadedHandler}
                    in:animate={(hasEngaged) ? null : { duration: 250, start: 0.75, easing: quintOut }}
                    on:ended={videoCompleteHandler}
                    src={getVideoUrl(src)} 
                    on:load={imageLoadedHandler}
                    on:click={handleClick} 
                />
                {#if hasMute}
                    <div class="absolute bottom-4 right-4 -mx-2"
                            on:click={handleMuteClick}>
                        <MuteButton
                            enabled={true}
                            muted={muted}
                        />
                    </div>
                {/if}
            </div>
        {:else }
        <img class={`${(imagesHaveBorders) ? 'border-[1px] border-adchat-lightgray' : ''} rounded-2xl drop-shadow w-auto origin-top-left ${(visible) ? 'visible' : 'invisible'}  ${(makeHotSpot) ? 'cursor-pointer' : ''}`}
                alt={altTag}
                in:fade={{ duration: 500 }}
                style={`max-height: ${mediaHeight}px;`}
                src={posterImage} 
                on:click={handleClick} 
            />
        {/if}
    {/if}
</div>