<script>
	import snarkdown from "snarkdown";
  import { createEventDispatcher } from "svelte";

  export let label;
  export let styles;
  export let url = null;
  export let postback = null;
  export let rtl = false;
  export let isHovering = false;
  export let compactMode = false;
  export let centerMode = true;
  export let clickMode = false;
  export let size = null;
  export let hasCTAIcon = false;

  const dispatch = createEventDispatcher();

  function clickHandler(event) {
    event.preventDefault();
    if(clickMode){
      dispatch("click");
      return;
    }
    if (url && url.length) {
      dispatch("openUrl", url);
      return;
    }
    let expression = /(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gi;
    let regex = new RegExp(expression);
    if (postback.match(regex)) {
      dispatch("openUrl", postback);
    } else {
      dispatch("postback", postback);
    }
  }

  function paddingBySize(size){
    if(size === 'large') return 'py-3' 
    if(size === 'small') return 'py-1'
    return 'py-2'
  }
</script>
<div class={`text-center m-auto font-medium mb-1 ${(hasCTAIcon) ? 'max-h-[26px]' : ''}`}
    style={`
      ${(compactMode) ? 'display: inline-block;' : ''}
      ${(!centerMode) ? 'text-align: left; padding-left: 8px; padding-right: 8px;' : ''}
    `}>
    <div class={`button-text inline-block cursor-pointer bg-white px-4 mx-auto ${(size === 'large') ? 'rounded-4xl' : 'rounded-3xl'} font-sm drop-shadow snarkdown`}
      style="{`
    		color: ${ styles.brandColor };
        font-size: 13px;
    		background-color: ${ styles.secondaryBrandColor };
        opacity: ${(isHovering) ? 0.7 : 1};
        text-align: ${(centerMode) ? 'center' : "left"};
        ${(!compactMode && centerMode ) ? 'width:85%' : 'width: auto'};
        ${rtl ? 'direction: rtl;' : ''}
      `}"
      role="button"
      on:click={clickHandler}
      on:keydown={clickHandler}
      on:mouseenter={() => isHovering = true}
      on:mouseleave={() => isHovering = false}
    >
      {#if label !== undefined}
        <div class={`${paddingBySize(size)} inline-block`}>
          {snarkdown(label)}
        </div>
      {/if}

    {#if hasCTAIcon}
      <div class="inline-block pl-2 align-middle -mr-3 -mt-1">
        <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="11.1905" cy="12" r="11.1905" fill="white"/>
          <path d="M10.0714 8.64285L13.4286 12L10.0714 15.3571" stroke="#1C69D4" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
    {/if}   
  </div> 
</div>

<style>
  .button-text{
    transform: translate3d(0px, 0px, 0px);
  }
</style>